export default function mobileMenu() {
  const selectors = {
    wrapper: document.getElementById('open-menu-wrapper'),
    button: document.getElementById('open-menu'),
    navigation: document.getElementById('main-navigation'),
  }
  if (selectors.button) {
    selectors.button.addEventListener('click', e => {
      selectors.wrapper.classList.toggle('absolute')
      selectors.wrapper.classList.toggle('fixed')
      selectors.navigation.classList.toggle('is-open')
    })
  }
}
