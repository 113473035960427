import FlipClock from 'flipclock'

export default {
  init() {
    const el = document.querySelector('.countdown__init')
    if (el) {
      const clock = new FlipClock(
        el,
        new Date(Date.parse(el.dataset.countStart)),
        {
          countdown: true,
          face: 'DayCounter',
        },
      )
    }
  },
  finalize() {},
}
