import FontFaceObserver from 'fontfaceobserver'
import mobileMenu from '../components/mobile-menu'

export default {
  init() {
    // JavaScript to be fired on all pagesvar font = new FontFaceObserver('My Family');
    const Montserrant300 = new FontFaceObserver('Montserrat', {
      weight: 300,
    })
    const Montserrant400 = new FontFaceObserver('Montserrat', {
      weight: 400,
    })
    const Montserrant600 = new FontFaceObserver('Montserrat', {
      weight: 600,
    })
    const Montserrant700 = new FontFaceObserver('Montserrat', {
      weight: 700,
    })
    Promise.all([
      Montserrant300.load(),
      Montserrant400.load(),
      Montserrant600.load(),
      Montserrant700.load(),
    ]).then(function() {
      document.documentElement.classList.add('fonts-loaded')
    })
    mobileMenu()
  },
}
